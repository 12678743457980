import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import { menuItems } from "../constants/menu";

const Sidebar = ({ isMinimized, isMobileOpen, closeSidebar }) => {
  const user = useSelector((state) => state.auth.user) || { name: "Guest", photo: "", role: 2 };
  const userRole = user?.role;
  const { settings } = useSelector((state) => state.globalSettings);
  const [openMenu, setOpenMenu] = useState({});

  const toggleSubmenu = (id) => {
    setOpenMenu((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  return (
    <>
      {isMobileOpen && (
        <div
          onClick={closeSidebar}
          className="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden"
        ></div>
      )}

      <div
        className={`bg-[#2D3D4D] text-[#afb9cf] ${
          isMobileOpen ? "block fixed z-50" : "hidden"
        } md:block ${isMinimized ? "w-18" : "w-60"} h-screen md:relative md:h-auto transition-all sidebar`}
      >
        <div className="p-4">
          <img
            src={isMinimized ? settings?.favicon : settings?.logo}
            alt="Logo"
            className={`${isMinimized ? "w-8" : "w-32"} h-auto`}
          />
        </div>
        {menuItems.map((menuSection) => (
          <div key={menuSection.section} className="mb-4">
            {/* Section Title */}
            <div className="px-4 py-2 text-xs font-bold uppercase text-[#afb9cf] menu-title">
            {!isMinimized && <span>{menuSection.section}</span>}
            </div>
            {/* Menu Items */}
            {menuSection.items.map((menu) => (
              (!menu.requiredRole || userRole >= menu.requiredRole) && (
                <div key={menu.id} className="px-2">
                  <Link
                    to={menu.to || "#"}
                    onClick={menu.submenu ? null : closeSidebar}
                    className={`flex items-center justify-between px-4 py-2 rounded-lg cursor-pointer hover:bg-[#3A4C5E] ${
                      openMenu[menu.id] ? "bg-[#3A4C5E]" : ""
                    } main-link`}
                    onClick={() => menu.submenu && toggleSubmenu(menu.id)}
                  >
                    <div className="flex items-center">
                      <i className={`${menu.icon} mr-3`}></i>
                      {!isMinimized && <span>{menu.name}</span>}
                    </div>
                    {menu.submenu && !isMinimized && (
                      <i
                        className={`fas ${
                          openMenu[menu.id] ? "fa-chevron-up" : "fa-chevron-down"
                        }`}
                      ></i>
                    )}
                    {menu.badge && !isMinimized && (
                      <span className="ml-2 bg-red-500 text-white text-xs px-2 py-1 rounded-full">
                        {menu.badge}
                      </span>
                    )}
                  </Link>
                  {/* Submenu */}
                  {menu.submenu && openMenu[menu.id] && (
                    <ul className="ml-6 mt-2 space-y-1">
                      {menu.submenu.map((sub) => (
                        <li key={sub.id} className="pl-4 pt-2 text-sm hover:text-white">
                          <Link to={sub.to} onClick={closeSidebar}>
                            <i className={`${sub.icon} mr-2`}></i>
                            {sub.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default Sidebar;
