import React from 'react';

const Modal = ({ title, children, onClose, footer }) => {
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
      <div className="relative bg-white rounded-md shadow-lg w-[600px]  widget">
        <div className="border-b p-4 flex justify-between items-center">
          <h2 className="text-lg font-semibold">{title}</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600 transform hover:rotate-180 transition duration-300"
          >
            <i className="fas fa-times"></i>
          </button>
        </div>

        <div className="p-4">{children}</div>

        {footer && <div className="border-t p-4 flex justify-end space-x-4">{footer}</div>}
      </div>
    </div>
  );
};

export default Modal;
