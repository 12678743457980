import axios from 'axios';
import { useAuthStore } from './authStore/index';
import i18n from '../constants/i18next';
import { AUTH_TOKEN_TYPE, baseUrl, TOKEN_PAYLOAD_KEY } from '../constants/api.constant';

const axiosInstance = axios.create({
  baseURL: baseUrl,
  timeout: 16000,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const { accessToken } = useAuthStore.getState();
    if (accessToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = `${AUTH_TOKEN_TYPE} ${accessToken}`;
    }

    if (config.method === 'get') {
      config.params = { lang: i18n.language, ...config.params };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { refreshToken, setTokens, clearTokens } = useAuthStore.getState();

    const originalRequest = error.config;
    if (
      error.response?.status === 401 &&
      error.response?.data?.message === 'Token expired' &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      try {
        const { data } = await axios.post(`${baseUrl}/auth/refresh-token`, {
          refreshToken,
        });

        setTokens(data.accessToken, refreshToken);
        originalRequest.headers[TOKEN_PAYLOAD_KEY] = `${AUTH_TOKEN_TYPE} ${data.accessToken}`;

        return axiosInstance(originalRequest);
      } catch (refreshError) {
        console.error('Refresh token failed:', refreshError);
        clearTokens();
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
