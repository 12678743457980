import request from './request';

const rolesService = {
  getRoles: () => request.get('roles'),
  createRole: (roleData) => request.post('roles', roleData),
  updateRole: (roleId, roleData) => request.put(`roles/${roleId}`, roleData),
  deleteRole: (roleId) => request.delete(`roles/${roleId}`),
};

export default rolesService;
