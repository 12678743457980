import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import settingService from '../../services/settings';

const initialState = {
  loading: false,
  settings: null,
  error: '',
};

export const fetchSettings = createAsyncThunk(
  'settings/fetchSettings',
  async (params = {}, { rejectWithValue }) => {
    try {
      const response = await settingService.get({ ...params });
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message || 'Failed to fetch settings');
    }
  }
);

const settingSlice = createSlice({
  name: 'settings',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettings.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.settings = action.payload.data;
        state.error = '';
      })
      .addCase(fetchSettings.rejected, (state, action) => {
        state.loading = false;
        state.settings = null;
        state.error = action.payload || 'An error occurred while fetching settings.';
      });
  },
});

export default settingSlice.reducer;
