import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../context/theme-provider';
import ProfileDropdown from '../components/ui/ProfileDropdown';

const Topbar = ({ toggleSidebar }) => {
    const [isFullScreen, setIsFullScreen] = useState(false);

    const { theme, toggleTheme } = useTheme();

    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
            setIsFullScreen(true);
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
            setIsFullScreen(false);
        }
    };

    return (
        <div className="bg-white shadow-md p-3 flex justify-between items-center border-b max-w-full topbar">
            <div className="flex items-center space-x-4">
                <div onClick={toggleSidebar} className='text-xl'>
                    <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h7"></path></svg>
                </div>
                {/* <button onClick={toggleSidebar} className="text-xl">
                    <i className="bx bx-menu"></i>
                </button> */}
                <h1 className="text-xl">Dashboard</h1>
            </div>
            <div className="flex items-center space-x-4">
                {/* Dark/Light Mode Toggle */}
                <button
                    onClick={toggleTheme}
                    className="w-10 h-10 flex items-center justify-center rounded-full hover:bg-gray-200 dark:hover:bg-gray-600 transition"
                    aria-label="Toggle Dark Mode"
                >
                    <i className={`bx ${theme === 'dark' ? 'bx-sun' : 'bx-moon'} text-xl text-gray-800 dark:text-white`}></i>
                </button>

                {/* Fullscreen Toggle */}
                <button
                    onClick={toggleFullScreen}
                    className="w-10 h-10 flex items-center justify-center rounded-full hover:bg-gray-200 dark:hover:bg-gray-600 transition"
                    aria-label="Toggle Fullscreen"
                >
                    <i className={`bx ${isFullScreen ? 'bx-exit-fullscreen' : 'bx-fullscreen'} text-xl`}></i>
                </button>

                <ProfileDropdown />
            </div>

        </div>
    );
};

export default Topbar;
