import { createSlice } from '@reduxjs/toolkit';
import { AUTH_TOKEN, REFRESH_TOKEN} from '../../constants/api.constant';

const initialState = {
  user: null,
  accessToken: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserData(state, action) {
      const { payload } = action;
      state.user = payload;
    },
    updateUser(state, action) {
      const { payload } = action;
      state.user = {
        ...state.user,
        ...payload,
        name: payload.name,
      };
    },
    clearUser(state) {
      state.user = null;
    },
    logout(state) {
        state.user = null;
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem(REFRESH_TOKEN)
    },
    setAccessToken(state, action) {
      const { payload } = action;
      state.accessToken = payload;
    },
  },
});

export const { setUserData, clearUser, updateUser, logout, setAccessToken } =
  authSlice.actions;
export default authSlice.reducer;
