export const menuItems = [
    {
      section: "GENERAL",
      items: [
        {
          id: 1,
          name: "Dashboard",
          icon: "bx bx-category-alt text-2xl",
          to: "/dashboard",
        },
      ],
    },
    {
      section: "USER MANAGEMENT",
      items: [
        {
          id: 2,
          name: "Members",
          icon: "fas fa-users",
          to: "/members",
          requiredRole: 1,
        },
        {
          id: 3,
          name: "User Roles",
          icon: "fas fa-user-tag",
          to: "/roles",
          requiredRole: 1,
        },
      ],
    },
    {
      section: "MONITORING",
      items: [
        {
          id: 4,
          name: "Activity Logs",
          icon: "fas fa-list-alt",
          to: "/activity-logs",
        },
      ],
    },
    {
      section: "SETTINGS",
      items: [
        {
          id: 5,
          name: "Control Panel",
          icon: "fas fa-cog",
          submenu: [
            {
              id: 5.1,
              name: "General Settings",
              icon: "fas fa-sliders-h",
              submenu: [
                { id: 5.11, name: "Site Settings", icon: "fas fa-globe", to: "/control-panel/site-settings" },
                { id: 5.12, name: "Email Settings", icon: "fas fa-envelope", to: "/control-panel/email-settings" },
              ],
            },
            {
              id: 5.2,
              name: "User Management",
              icon: "fas fa-users-cog",
              submenu: [
                { id: 5.21, name: "Permissions", icon: "fas fa-key", to: "/control-panel/permissions" },
                { id: 5.22, name: "Roles", icon: "fas fa-user-tag", to: "/control-panel/roles" },
              ],
            },
          ],
          requiredRole: 1,
        },
      ],
    },
    {
      section: "DEVELOPMENT",
      items: [
        {
          id: 6,
          name: "API Documentation",
          icon: "fas fa-code",
          to: "/api-docs",
          requiredRole: 1,
        },
      ],
    },
  ];
  