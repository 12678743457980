import { useSelector } from 'react-redux';

const Footer = () => {
    const { settings} = useSelector((state) => state.globalSettings);

    return (
        <div className="bg-white text-gray-600 text-sm p-4 text-center border-t footer">
            &copy; {new Date().getFullYear()} {settings?.websiteName}. All Rights Reserved.
        </div>
    );
};

export default Footer;
