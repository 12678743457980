import { create } from 'zustand';

import { AUTH_TOKEN, REFRESH_TOKEN } from '../../constants/api.constant';

export const useAuthStore = create((set) => ({
  accessToken: localStorage.getItem(AUTH_TOKEN),
  refreshToken: localStorage.getItem(REFRESH_TOKEN),
  setTokens: (accessToken, refreshToken) => {
    if (accessToken) localStorage.setItem(AUTH_TOKEN, accessToken);
    if (refreshToken) localStorage.setItem(REFRESH_TOKEN, refreshToken);
    set({ accessToken, refreshToken });
  },
  clearTokens: () => {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    set({ accessToken: null, refreshToken: null });
  },
}));
