import React, { useState, useEffect } from 'react';
import LoadingButton from '../components/ui/LoadingButton';
import { toast } from 'react-toastify';
import Modal from './ui/Modal';
import StyledDatePicker from './ui/DatePicker';
import memberService from '../services/member';

const EditMemberModal = ({ member, closeModal, handleMemberUpdated }) => {
    const [name, setName] = useState(member.name);
    const [username, setUserName] = useState(member.user.username);
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState(member.email);
    const [dob, setDob] = useState(member.date_of_birth ? member.date_of_birth.split('T')[0] : '');
    const [photo, setPhoto] = useState(null);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState(member.role_id || '');

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const data = await memberService.getRoles();
                setRoles(data);
            } catch (err) {
                console.error('Failed to fetch roles:', err.message);
            }
        };

        fetchRoles();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        const formData = new FormData();
        formData.append('name', name);
        formData.append('username', username);
        formData.append('email', email);
        formData.append('dob', dob);
        formData.append('password', password);
        formData.append('role_id', selectedRole);

        if (photo) formData.append('profilePicture', photo);

        try {
            const res = await memberService.updateMember(member.id, formData);
            handleMemberUpdated();
            toast.success('Member updated successfully');
            closeModal();
        } catch (err) {
            toast.error('Failed to update member');
            setError('Failed to update member');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
          title="Edit Member"
          onClose={closeModal}
          footer={
            <>
              <button onClick={closeModal} className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md">
                Cancel
              </button>
              <LoadingButton isLoading={isLoading} type="button" onClick={handleSubmit}>
                Save Changes
              </LoadingButton>
            </>
          }
        >
          <form onSubmit={handleSubmit} className="p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
             
                <div>
                  <label className="block text-sm font-medium mb-1">Name</label>
                  <input
                    type="text"
                    className="w-full border p-2 rounded-md"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter member name"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Username</label>
                  <input
                    type="text"
                    className="w-full border p-2 rounded-md"
                    value={username}
                    onChange={(e) => setUserName(e.target.value)}
                    placeholder="Enter username"
                    required
                  />
                </div>
      
                <div>
                  <label className="block text-sm font-medium mb-1">Email</label>
                  <input
                    type="email"
                    className="w-full border p-2 rounded-md"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter email address"
                    required
                  />
                </div>
                <div>
                    <label className="block text-sm font-medium mb-1">Date of Birth</label>
                    <StyledDatePicker
                        value={dob ? new Date(dob) : null}
                        onChange={(newDate) => setDob(newDate.toISOString().split('T')[0])}
                    />
                </div>
      
                <div>
                  <label className="block text-sm font-medium mb-1">Select Role</label>
                  <select
                    className="w-full border p-2 rounded-md"
                    value={selectedRole}
                    onChange={(e) => setSelectedRole(e.target.value)}
                    required
                  >
                    <option value="">Select a Role</option>
                    {roles.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                  </select>
                </div>
      
                <div>
                  <label className="block text-sm font-medium mb-1">Password</label>
                  <input
                    type="password"
                    className="w-full border p-2 rounded-md"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter new password"
                  />
                </div>
      
                <div className="md:col-span-2">
                  <label className="block text-sm font-medium mb-1">Photo</label>
                  <input
                    type="file"
                    className="w-full border p-2 rounded-md"
                    onChange={(e) => setPhoto(e.target.files[0])}
                  />
                </div>
              </div>
            </form>
        </Modal>
      );     
      
};

export default EditMemberModal;
