import request from './request';

const memberService = {
  getMembers: () => request.get('members'),
  createMember: (data) => request.post('members', data),
  updateMember: (id, data) => request.put(`members/${id}`, data),
  deleteMember: (id) => request.delete(`members/${id}`),
  getProfile: () => request.get('members/profile'),
  updateProfile: (data) =>
    request.put('members/profile', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),
  changePassword: (data) => request.put('members/profile/change-password', data),
  getRoles: () => request.get('members/roles'),
};

export default memberService;
