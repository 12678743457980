import React, { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';

const StyledDatePicker = ({ value, onChange }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={value}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            InputProps={{
              ...params.InputProps,
              sx: {
                '& .MuiInputBase-root': {
                  padding: '8px 12px',
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                },
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default StyledDatePicker;
